<template>
    <div class="settings">
        <van-nav-bar class="bar" :border="false" :title="$t('user.account_security')" left-arrow @click-left="onClickLeft" />
        <h3 class="pc-title">{{ $t('user.account_security') }}</h3>
        <div style="height: 12px;"></div>
        <div class="h5-box">
            <van-cell class="settings-cell" :title="$t('user.login_password')" is-link to="/login_password" />
            <van-cell class="settings-cell" :title="$t('user.withdraw_password')" is-link to="/cash_password" />
        </div>
        <div class="pc-box">
            <div class="line">
                <span>{{ $t('user.login_password') }}</span>
                <div class="btn" @click="openUrl('/my/login_password')">{{ $t('user.go_setting') }}</div>
            </div>
            <div class="line">
                <span>{{ $t('user.withdraw_password') }}</span>
                <div class="btn" @click="openUrl('/my/cash_password')">{{ $t('user.go_setting') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        onClickLeft() {
            this.$router.back();
        },
        openUrl(url) {
            this.$router.push(url);
        }
    },
};
</script>
<style lang="less" scoped>
.settings {
    min-height: 100vh;
    background: url(../../../assets/login_bg.png) left top no-repeat #fff;
    background-size: 100%;
    .bar {
        background: none;
    }
    .settings-cell {
        margin: 14px 16px;
        width: 343px;
        height: 50px;
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
    }
}
.bar /deep/ .van-nav-bar__left .van-icon {
    color: #333333;
}

.pc-title {
    display: none;
}
.pc-box {
    display: none;
}
@media only screen and (min-width: 750px) {
    .bar {
        display: none;
    }
    .h5-box {
        display: none;
    }
    .settings {
        background: none;
        padding: 42px;
    }
    .pc-title {
        display: block;
        font-weight: 800;
        font-size: 28px;
        color: #333333;
    }
    .pc-box {
        display: block;
        .line {
            display: flex;
            align-items: center;
            margin: 26px 0;
            span {
                display: block;
                width: 35%;
                font-size: 16px;
                color: #222222;
            }
            .btn {
                width: 141px;
                height: 50px;
                line-height: 50px;
                background: linear-gradient(270deg, #a44afb 0%, #f85dc6 100%);
                border-radius: 8px 8px 8px 8px;
                text-align: center;
                font-size: 16px;
                color: #ffffff;
                cursor: pointer;
            }
        }
    }
}
</style>